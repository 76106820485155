<template>
    <v-container>
        <v-card v-if="this.$store.state.utente.isGestoreStudio" class="mb-2" :loading="loadingChiudi">
            <v-card-title>
                <v-icon x-large left>
                    mdi-calendar-multiple-check
                </v-icon>
                <span class="text-subtitle-1 font-weight-medium">
                    Anno contabile impostato: {{$store.state.anno}}
                </span>
                <span class="text-subtitle-1 font-weight-medium red--text" v-if="!$store.state.annoAperto">[Chiuso]</span>
    
            </v-card-title>
            <v-card-text>
                <span>Da questa sezione è possibile chiudere l'anno contabile aperto. Questa operazione si effettua di norma a fine dicembre o a inizio gennaio.</span>
                <br/>
                <span>Per ogni cliente viene determinato il saldo della scheda contabile e, se diverso da 0 €, vieni impostato come Saldo Anno Precedente nella nuova scheda.</span>
                <br/>
                <span>Le tariffe contabili vengono ricopiate sul nuovo anno. E' possibile poi utilizzare il tool 'Tariffe' per effettuare ritocchi singoli o generalizzati.</span>
                <br/>
                <br/>
                <span>Al termine della procedura, si consigli di far riconnettere tutti i client collegati.</span>

                <v-row class="mt-12">
                    <v-col align="center">
                        <v-btn :dark="$store.state.annoAperto" 
                        :disabled="!$store.state.annoAperto" 
                        color="red"
                        @click="chiudi"
                        >Chiudi Anno {{$store.state.anno}}</v-btn>
                    </v-col>
                </v-row>       
            </v-card-text>
        </v-card>  
        <v-card :loading="loading">
            <v-card-title>
                <v-icon x-large left>
                    mdi-calendar-expand-horizontal-outline
                </v-icon>
                <span class="text-subtitle-1 font-weight-medium">
                    Cambia anno contabile
                </span>
    
            </v-card-title>
            <v-card-text>
                <v-row class="mt-12">
                    <v-col align="center">
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="idAnno"
                                    :items="lista"
                                    item-value="id"
                                    item-text="anno"
                                    label="Seleziona anno"
                                    hint="E' possibile impostare un anno precedente per visionare le schede clienti chiuse. Non è possibile registrare addebiti o pagamenti per gli anni già chiusi"
                                    persistent-hint
                                    outlined
                                    dense
                            ></v-select>
                            </v-col>
                            <v-col>
                                <v-btn 
                                @click="setAnno"
                                :disabled="!idAnno" 
                                color="info">
                                Imposta Anno 
                            </v-btn>
                            </v-col>
                            <v-col></v-col>
                        </v-row>                   
                    </v-col>
                </v-row>            
    
            </v-card-text>
        </v-card>  
        <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        centered
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Anno {{$store.state.anno}} impostato
            </v-snackbar>
            <v-snackbar v-model="snackError" centered
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
            <v-overlay :value="loading || loadingChiudi"></v-overlay>
    </v-container>
    
      
</template>



<script>
    import { callService, callPost, gLocalDateTime, gLocalData, gSynch5} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data: () => ({
            loading: false,
            loadingChiudi: false,
            snackSuccess:false,
            snackError: false,
            hCard: 450,
            lista:[],
            idAnno: ""            
        }),
        methods: {            
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            async onPraticaChanged(){
                await this.internalCreated();
            },  
            async setAnno() {
                this.loading = true;
                let param = [];

                this.$store.dispatch('setIDAnnoContabile',this.idAnno);
                let annoContabile = this.lista.find(x=>x.id==this.idAnno);
                this.$store.dispatch('setAnno',annoContabile.anno);
                this.$store.dispatch('setAnnoAperto',annoContabile.isAperto);
                await gSynch5();
                this.loading = false;
                this.snackSuccess = true;
            },            
            async chiudi() {
                let item = this.idAnno;
                let anno = this.$store.state.anno;
                let annoNuovo= anno+1;
                bus.$emit('msg-open', { message: "Chiudere l'anno "+this.$store.state.anno+"? I Saldi dei clienti saranno riportati al nuovo anno "+annoNuovo+".", item });                
                bus.$on('msg-cancel', this.chiudiCancel); 
                bus.$on('msg-ok', this.chiudiOk);
            },  
            async chiudiCancel(item) {
                bus.$off('msg-cancel', this.chiudiCancel);
                bus.$off('msg-ok', this.chiudiOk);
            },
            async chiudiOk(item) {
                bus.$off('msg-cancel', this.chiudiCancel);
                bus.$off('msg-ok', this.chiudiOk);
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                };

                this.loadingChiudi=true;
                let ok = await callPost('api/annoContabile', paramJson);
                if(ok) {

                    let param = [];
                    let annoContabile = await callService("api/annoContabile", param);

                    this.$store.dispatch('setIDAnnoContabile',annoContabile.id);
                    this.$store.dispatch('setAnno',annoContabile.anno);
                    this.$store.dispatch('setAnnoAperto',annoContabile.isAperto);
                    await gSynch5();
                    this.loadingChiudi=false;
                    this.snackSuccess = true;
                }
                else {
                    this.loadingChiudi=false;
                    this.snackError = true;
                }
            },
        },
        computed: {
            
        },
        async created() {
            this.loading = true;
            let param = [];
            this.lista = await callService("api/annoContabile/lista", param);
            this.loading = false;   
        },        
        beforeDestroy() {
        }
    }</script>
