<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="4">
                                        <v-tooltip v-if="isContabilita" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="blue darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewCA"
                                                       :disabled="selezione.length==0 || !$store.state.annoAperto">
                                                    <v-icon>mdi-alarm</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelCA}}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="isPulizia" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="blue darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewCS"
                                                       :disabled="selezione.length==0">
                                                    <v-icon>mdi-alarm</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelCS}}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="isPulizia" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="red"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewCSO"
                                                       :disabled="selezione.length==0">
                                                    <v-icon>mdi-alarm-off</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelCSO}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="orange darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewAS"
                                                       :disabled="selezione.length==0 || !$store.state.annoAperto">
                                                    <v-icon>mdi-cash-multiple</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelAS}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="teal"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewMB"
                                                       :disabled="selezione.length==0 || !$store.state.annoAperto">
                                                    <v-icon class="ma-0">mdi-broadcast</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelMB}}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="isContabilita" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="deep-orange darken-4"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewSC"
                                                       :disabled="selezione.length==0 || !$store.state.annoAperto">
                                                    <v-icon class="ma-0">mdi-calendar-sync</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelSC}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="blue-grey"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewSD"
                                                       :disabled="selezione.length==0 || !$store.state.annoAperto">
                                                    <v-icon class="ma-0">mdi-file-document-multiple-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelSD}}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="isLavoro" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="pink lighten-1"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewBP"
                                                       :disabled="!$store.state.annoAperto">
                                                    <v-icon class="ma-0">mdi-account-hard-hat-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Addebito Multiplo Lavoro</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="orange darken-2"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="viewAIB"
                                                       :disabled="selezione.length==0">
                                                    <v-icon>mdi-bank-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{labelAIB}}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="teal"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="nuovo"
                                                       :disabled="!$store.state.annoAperto">
                                                    <v-icon class="ma-0">mdi-account-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Nuovo Cliente</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="searchText"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca un cliente per codice, nominativo, piva, cf, etichetta"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @input="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-tooltip v-if="!fileExcel" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="brown"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       :loading="loadingExcel"
                                                       :disabled="selezione.length==0"
                                                       @click="listaExcel">
                                                    <v-icon>mdi-microsoft-excel</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Genera lista Excel dalla selezione</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="fileExcel" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :loading="loadingExcel"
                                                       icon
                                                       color="error"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="scaricaExcel">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Scarica file Excel</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="!filePdf" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="red"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       :loading="loadingPdf"
                                                       :disabled="selezione.length==0"
                                                       @click="programmaPdf">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Genera schede clienti dalla selezione</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="filePdf" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :loading="loadingPdf"
                                                       icon
                                                       color="red"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="visualizzaPdf">
                                                    <v-icon>mdi-download-box-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Scarica schede clienti dalla selezione</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n4">
                                    <v-col cols="3">
                                        <v-card>
                                            <v-card-text>
                                                <span class="text-subtitle-2">Filtri disponibili</span>
                                                <v-select v-model="operatore.id"
                                                          :items="listaOperatore"
                                                          item-value="id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Operatore"
                                                          :disabled="isAccessoLimitatoO"
                                                          clearable
                                                          outlined
                                                          class="mt-n0"
                                                          @change="changeSearch"
                                                          dense></v-select>
                                                <v-select v-if="isContabilita || isLavoro"
                                                          v-model="professionista.id"
                                                          :items="listaOperatoriProfessionisti"
                                                          item-value="professionista.id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Professionista"
                                                          :disabled="isAccessoLimitatoP"
                                                          clearable
                                                          class="mt-n3"
                                                          outlined
                                                          @change="changeSearch"
                                                          dense></v-select>
                                                <v-select v-model="fido"
                                                          :items="[
                                                    {color: 'teal', descrizione:'Clienti in attivo', colore: 'verde'},
                                                    {color: 'orange', descrizione:'Fido utilizzato', colore: 'giallo'},
                                                    {color: 'deep-orange darken-4', descrizione:'Fido superato', colore: 'rosso'}
                                                  ]"
                                                          dense
                                                          outlined
                                                          disable-lookup
                                                          class="mt-n3"
                                                          label="Situazione Fido"
                                                          item-text="descrizione"
                                                          item-value="colore"
                                                          @change="changeSearch"
                                                          clearable>
                                                    <template v-slot:selection="{item}">
                                                        <v-icon class="ml-2" :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                                        <span class="ml-2">{{item.descrizione}}</span>
                                                    </template>
                                                    <template v-slot:item="{item}">
                                                        <v-icon class="ml-2" :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                                        <span class="ml-2">{{item.descrizione}}</span>
                                                    </template>
                                                </v-select>
                                                <v-select v-if="isContabilita || isLavoro"
                                                          v-model="regimeIVA.id"
                                                          :items="listaTipoRegimeIVA"
                                                          item-value="id"
                                                          item-text="descrizione"
                                                          label="Regime IVA"
                                                          class="mt-n3"
                                                          clearable
                                                          outlined
                                                          @change="changeSearch"
                                                          dense></v-select>
                                                <v-select v-if="isContabilita || isLavoro"
                                                          v-model="regimeContabile.id"
                                                          :items="listaTipoRegimeContabile"
                                                          item-value="id"
                                                          item-text="descrizione"
                                                          label="Regime Contabile"
                                                          class="mt-n3"
                                                          clearable
                                                          outlined
                                                          @change="changeSearch"
                                                          dense></v-select>
                                                <v-select v-model="tipoCliente.id"
                                                          :items="listaTipoCliente"
                                                          item-value="id"
                                                          item-text="descrizione"
                                                          label="Tipologia cliente"
                                                          class="mt-n3"
                                                          clearable
                                                          outlined
                                                          @change="changeSearch"
                                                          dense></v-select>
                                                <v-select v-if="isContabilita || isLavoro"
                                                          v-model="statuspec"
                                                          :items="[
                                                    {color: 'teal', descrizione:'PEC valida', colore: 'verde'},
                                                    {color: 'yellow', descrizione:'PEC senza data', colore: 'giallo'},
                                                    {color: 'orange', descrizione:'PEC scaduta', colore: 'arancio'},
                                                    {color: 'deep-orange darken-4', descrizione:'PEC non presente', colore: 'rosso'}
                                                  ]"
                                                          dense
                                                          outlined
                                                          disable-lookup
                                                          class="mt-n3"
                                                          label="Situazione PEC"
                                                          item-text="descrizione"
                                                          item-value="colore"
                                                          @change="changeSearch"
                                                          clearable>
                                                    <template v-slot:selection="{item}">
                                                        <v-icon class="ml-2" :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                                        <span class="ml-2" style="font-size:small">{{item.descrizione}}</span>
                                                    </template>
                                                    <template v-slot:item="{item}">
                                                        <v-icon class="ml-2" :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                                        <span class="ml-2" style="font-size:small">{{item.descrizione}}</span>
                                                    </template>
                                                </v-select>
                                                <v-select v-model="statuscliente"
                                                          :items="[
                                                    {color: 'green', descrizione:'clienti operativi', colore: 'verde', sel: 'Ope'},
                                                    {color: 'cyan', descrizione:'clienti addebiti sospesi', colore: 'giallo', sel: 'Sos'},
                                                    {color: 'orange', descrizione:'clienti bloccati', colore: 'arancio', sel: 'Blo'},
                                                    {color: 'deep-orange darken-4', descrizione:'clienti cancellati', colore: 'rosso', sel: 'Can'}
                                                  ]"
                                                          dense
                                                          outlined
                                                          disable-lookup
                                                          class="mt-n3"
                                                          label="Status clienti"
                                                          item-text="descrizione"
                                                          item-value="colore"
                                                          @change="changeSearch"
                                                          multiple
                                                          clearable>
                                                    <template v-slot:selection="{item}">
                                                        <v-icon class="ml-2" :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                                        <span class="ml-2" style="font-size:smaller">{{item.sel}}</span>
                                                    </template>
                                                    <template v-slot:item="{item}">
                                                        <v-icon class="ml-2" :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                                        <span class="ml-2" style="font-size:small">{{item.descrizione}}</span>
                                                    </template>
                                                </v-select>
                                                <!--<v-radio-group @change="changeSearch" hide-details class="mt-0" v-model="statoCliente" mandatory>
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-radio label="Tutti"
                                                                 value="1"></v-radio>
                                                        <v-radio label="Sospesi" color="orange"
                                                                 value="3"></v-radio>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-radio label="Attivi" color="teal"
                                                                 value="2"></v-radio>
                                                        <v-radio label="Eliminati" color="deep-orange darken-4"
                                                                 value="4"></v-radio>
                                                    </v-col>
                                                </v-row>
                                            </v-radio-group>-->

                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-data-table v-model="selezione"
                                                      :headers="headers"
                                                      :items="clientiEstesi"
                                                      item-key="cliente.id"
                                                      ref="tabellaClienti"
                                                      :loading="loading"
                                                      progress="78"
                                                      :items-per-page="nPag"
                                                      class="elevation-1"
                                                      :item-class="coloraStatus"
                                                      :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                                      show-select
                                                      @toggle-select-all="toggleAll"
                                                      @item-selected="toggleSelect"
                                                      @current-items="filterItems"
                                                      @pagination="pagination"
                                                      @click:row="clickRowCliente"
                                                      :single-select="false"
                                                      :search="search"
                                                      :custom-filter="customFilter"
                                                      loading-text="Caricamento dati..."
                                                      no-data-text="Nessun cliente presente in archivio"
                                                      no-results-text="Nessun cliente corrispodende ai criteri di ricerca">
                                            <template v-slot:item.azioni="{item}">
                                                <v-row>
                                                    <v-col class="mx-n4">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn class="mx-n4" icon @click="anagrafica(item.cliente)"
                                                                       v-bind="attrs"
                                                                       v-on="on">
                                                                    <v-icon color="blue darken-2">
                                                                        mdi-account
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Anagrafica Cliente</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col class="mx-n4">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn class="mx-n4" icon @click="scheda(item.cliente)"
                                                                       v-bind="attrs"
                                                                       v-on="on">
                                                                    <v-icon color="orange darken-2">
                                                                        mdi-tablet-dashboard
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Scheda Cliente</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-slot:item.dataUltimoPagamento="{ item }">
                                                <div v-if="item.importoUltimoPagamento>0">
                                                    <span v-text="importoEuro(item.importoUltimoPagamento)" />
                                                    <br />
                                                    <span class="font-weight-light" v-text="localData(item.dataUltimoPagamento)" />
                                                </div>
                                            </template>
                                            <template v-slot:header.cliente.denominazioneNormalizzata>
                                                Nominativo
                                                <br />
                                                P.IVA/C.Fiscale
                                            </template>
                                            <template v-slot:item.cliente.denominazioneNormalizzata="{ item }">
                                                <!--<span class="text-subtitle font-weight-medium" v-text="item.cliente.denominazioneNormalizzata" />-->
                                                <span :class="item.statuscliente=='rosso' ? 'red--text': (item.statuscliente=='arancio' ? 'orange--text': (item.statuscliente=='giallo' ? 'cyan--text': 'green--text'))"
                                                      dark>
                                                    <b>{{item.cliente.denominazioneNormalizzata}}</b>
                                                </span>
                                                <br />
                                                <span v-if="!item.cliente.tipoCliente.ordinamento==6" class="font-weight-light" v-text="item.cliente.partitaIVA+' '" />
                                                <span v-if="item.cliente.codiceFiscale" class="font-weight-light" v-text="item.cliente.codiceFiscale" />
                                                <span v-else class="font-weight-light" v-text="item.cliente.codiceFiscaleLR" />
                                            </template>
                                            <template v-slot:item.saldo="{ item }">
                                                <span :class="item.fido=='rosso' ? 'deep-orange--text text--darken-4':(item.fido=='giallo' ? 'orange--text':'teal--text')"
                                                      dark>
                                                    <b>{{importoEuro(item.saldo)}}</b>
                                                </span>
                                            </template>
                                            <template v-slot:item.listaNomi="{ item }">
                                                <v-chip small dark color="gray"
                                                        v-for="operatore in item.operatori"
                                                        :key="operatore.id"
                                                        v-text="operatore.nomeNormalizzato">
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.cliente.tags="{ item }">
                                                <v-chip small dark color="gray"
                                                        v-for="tag in item.cliente.tagList"
                                                        :key="tag"
                                                        @click="setTagRicerca(tag)"
                                                        v-text="tag">
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.bTariffaContratto="{ item }">
                                                <v-icon v-if="item.bTariffaContratto" color="teal">mdi-file-document-edit-outline</v-icon>
                                                <v-icon v-else="item.bTariffaContratto" color="red">mdi-file-document-edit</v-icon>
                                            </template>
                                            <template v-slot:header.bTariffaContratto="{ item }">
                                                <span v-html="$store.state.conf.isService ? 'Contratti':'Tariffe'" />
                                            </template>
                                            <template v-slot:item.elimina="{ item }">
                                                <div v-if="item.cliente.isCancellabile && $store.state.utente.isGestoreStudio">
                                                    <v-btn icon @click="elimina(item)">
                                                        <v-icon class="ml-3" color="error darken-2">mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </div>
                                                <div v-else>
                                                    <v-icon class="ml-3" color="gray">mdi-trash-can-outline</v-icon>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <v-dialog v-model="dialogMB" persistent
                  max-width="800">
            <v-stepper v-model="mb">
                <v-stepper-header>
                    <v-stepper-step :complete="mb > 1"
                                    step="1">
                        Messaggio
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="mb > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formMB"
                                    v-model="validMB">
                                <v-row>
                                    <v-col cols="8" offset="2">
                                        <v-text-field v-model="oggetto"
                                                      label="Oggetto della comunicazione"
                                                      outlined
                                                      :rules="[v=> !!v || 'inserire oggetto']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="8" offset="2">
                                        <v-textarea v-model="testo"
                                                    outlined
                                                    label="Testo della comunicazione"
                                                    :rules="[v=> !!v || 'inserire testo']">
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validMB" color="primary" depressed
                                       @click="mb = 2">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogMB = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="6">
                                    <span class="text-h6 font-weight-bold">{{oggetto}}</span>
                                </v-col>
                                <v-col cols="6" align="right">
                                    <span class="text-h6">Clienti selezionati: </span>
                                    <span class="text-h6 font-weight-bold">{{selezione.length}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8">
                                    <span class="body-1">{{testo}}</span>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="mb = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendMB">
                                    Invia
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogMB = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingMB" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingMB && esitoMBOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingMB && esitoMBKO" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogMB = false"
                                       color="warning"
                                       :disabled="loadingMB">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>


        <v-dialog v-model="dialogCA" persistent
                  max-width="1000">
            <v-stepper v-model="ca">
                <v-stepper-header>
                    <v-stepper-step :complete="ca > 1"
                                    step="1">
                        Scelta mensilità
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="ca > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formCA"
                                    v-model="validCA">
                                <v-row>

                                    <v-col cols="6" offset="3">
                                        <v-select v-model="idPeriodo"
                                                  :items="periodi"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Mensilità"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare mensilità']"
                                                  dense></v-select>
                                        <v-text-field v-model="dataAddebito"
                                                      label="Data addebito"
                                                      outlined
                                                      style="width:200px"
                                                      :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense>
                                        </v-text-field>
                                        <v-checkbox class="ml-2" v-model="notifica"
                                                    label="notifica addebito in StudioPro App"
                                                    color="red darken-3">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validCA" :loading="loadingCA" color="primary" depressed
                                       @click="stepCA1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCA = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container>
                            <v-row dense>
                                <v-col cols="6" offset="1">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-calendar</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{dataAddebito}}</v-list-item-title>
                                            <v-list-item-subtitle>data addebito</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-for="(p,index) in periodiConnessi" :key="p.id">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{p.tipoPeriodicita.descrizione}}</v-list-item-title>
                                            <v-list-item-subtitle>{{p.descrizione}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiOk[index])">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{conteggioClienti[index]}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiNoTariffa.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Tariffa non impostata/incompatibile</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Attenzione: non sarà addebitato nulla</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiNoTariffa)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiNoTariffa.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiTariffaAddebitata.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Tariffa già addebitata</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Attenzione: non sarà addebitato nulla</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiTariffaAddebitata)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiTariffaAddebitata.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiNoAddebito.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Clienti non addebitabili</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Clienti eliminati, bloccati o con addebito sospeso</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiNoAddebito)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiNoAddebito.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="ca = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendCA">
                                    Addebita canone
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCA = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingCA" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCA && esitoCAOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCA && !esitoCAOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCA = false"
                                       color="warning"
                                       :disabled="loadingCA">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogCS" persistent
                  max-width="1000">
            <v-stepper v-model="cs">
                <v-stepper-header>
                    <v-stepper-step :complete="cs > 1"
                                    step="1">
                        Scelta mensilità
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="cs > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formCS"
                                    v-model="validCS">
                                <v-row>

                                    <v-col cols="6" offset="3">
                                        <v-select v-model="idPeriodo"
                                                  :items="periodi"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Mensilità"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare mensilità']"
                                                  dense></v-select>
                                        <v-text-field v-model="dataAddebito"
                                                      label="Data addebito"
                                                      outlined
                                                      style="width:200px"
                                                      :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense>
                                        </v-text-field>
                                        <v-checkbox class="ml-2" v-model="notifica"
                                                    label="notifica addebito in ServicePro App"
                                                    color="red darken-3">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validCS" :loading="loadingCS" color="primary" depressed
                                       @click="stepCS1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCS = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container>
                            <v-row dense>
                                <v-col cols="6" offset="1">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-calendar</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{dataAddebito}}</v-list-item-title>
                                            <v-list-item-subtitle>data addebito</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-for="(p,index) in periodiConnessi" :key="p.id">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{p.tipoPeriodicita.descrizione}}</v-list-item-title>
                                            <v-list-item-subtitle>{{p.descrizione}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiOk[index])">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{conteggioClienti[index]}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiNoTariffa.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Tariffa non impostata/incompatibile</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Attenzione: non sarà addebitato nulla</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiNoTariffa)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiNoTariffa.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiTariffaAddebitata.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Contratto già addebitato</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Attenzione: non sarà addebitato nulla</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiTariffaAddebitata)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiTariffaAddebitata.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiNoAddebito.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Clienti non addebitabili</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Clienti eliminati, bloccati o con addebito sospeso</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiNoAddebito)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiNoAddebito.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="cs = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendCS">
                                    Addebita canone/Pianifica servizi
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCS = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingCS" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCS && esitoCSOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCS && !esitoCSOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCS = false"
                                       color="warning"
                                       :disabled="loadingCS">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogCSO" persistent
                  max-width="1000">
            <v-stepper v-model="cso">
                <v-stepper-header>
                    <v-stepper-step :complete="cso > 1"
                                    step="1">
                        Scelta mensilità
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="cso > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formCSO"
                                    v-model="validCSO">
                                <v-row>

                                    <v-col cols="6" offset="3">
                                        <v-select v-model="idPeriodo"
                                                  :items="periodi"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Mensilità"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare mensilità']"
                                                  dense></v-select>
                                        <!--<v-text-field v-model="dataAddebito"
                                                  label="Data addebito"
                                                  outlined
                                                  style="width:200px"
                                                  :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                  hint="gg/mm/aaaa"
                                                  persistent-hint
                                                  clearable
                                                  dense>
                                    </v-text-field>-->
                                        <!--<v-checkbox class="ml-2" v-model="notifica"
                                                label="notifica addebito in ServicePro App"
                                                color="red darken-3">
                                    </v-checkbox>-->
                                        <p style="color:red" align="justify">
                                            Attenzione: saranno eliminati tutti i servizi relativi al mese selezionato anche se si tratta di servizi già lavorati. <strong>La cancellazione è irreversibile</strong>.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validCSO" :loading="loadingCSO" color="primary" depressed
                                       @click="stepCSO1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCSO = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container>
                            <!--<v-row dense>
                            <v-col cols="6" offset="1">
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-calendar</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{dataAddebito}}</v-list-item-title>
                                        <v-list-item-subtitle>data addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>-->
                            <v-row dense v-for="(p,index) in periodiConnessi" :key="p.id">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted-type</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{p.tipoPeriodicita.descrizione}}</v-list-item-title>
                                            <v-list-item-subtitle>{{p.descrizione}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiOk[index])">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{conteggioClienti[index]}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti con addebito</v-list-item-subtitle>
                                            <v-list-item-subtitle class="red--text">Azione prevista: eliminazione</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiNoTariffa.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Tariffa non impostata/incompatibile</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Nessuna azione prevista</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiNoTariffa)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiNoTariffa.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiTariffaAddebitata.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Contratto addebitato</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Eliminazione addebito/servizi</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiTariffaAddebitata)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiTariffaAddebitata.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti totali operazione</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="listaClientiNoAddebito.length>0">
                                <v-col cols="6" offset="1">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-alert-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">Clienti non addebitabili</v-list-item-title>
                                            <v-list-item-subtitle class="red--text">Clienti eliminati, bloccati o con addebito sospeso</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn icon @click="visualizzaClienti(listaClientiNoAddebito)">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{listaClientiNoAddebito.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="cso = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendCSO">
                                    Elimina addebito e servizi collegati
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCSO = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingCSO" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCSO && esitoCSOOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCSO && !esitoCSOOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCSO = false"
                                       color="warning"
                                       :disabled="loadingCSO">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogAIB" persistent
                  max-width="1000">
            <v-stepper v-model="aib">
                <v-stepper-header>
                    <v-stepper-step :complete="aib > 1"
                                    step="1">
                        Scelta conto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="aib > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formAIB"
                                    v-model="validAIB">
                                <v-row>

                                    <v-col cols="8" offset="2">
                                        <v-select v-model="idConto"
                                                  :items="conti"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Conto"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare conto']"
                                                  dense></v-select>
                                        <p align="justify">
                                            La funzione assegnerà ai clienti selezionati il conto standard per i bonifici. Il conto selezionato comparirà nella scheda del cliente.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validAIB" :loading="loadingAIB" color="primary" depressed
                                       @click="stepAIB1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogAIB = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="8" offset="2">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-bank</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Conto prescelto</v-list-item-subtitle>
                                            <v-list-item-title class="text-h6">{{descrizioneConto}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" offset="2">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-format-list-bulleted</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Clienti selezionati</v-list-item-subtitle>
                                            <v-list-item-title class="text-h6 blue--text">{{selezione.length}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="aib = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendAIB">
                                    Assegna Conto
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogAIB = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingAIB" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingAIB && esitoAIBOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingAIB && !esitoAIBOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogAIB = false"
                                       color="warning"
                                       :disabled="loadingAIB">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>


        <v-dialog v-model="dialogSC" persistent
                  max-width="800">
            <v-stepper v-model="sc">
                <v-stepper-header>
                    <v-stepper-step :complete="sc > 1"
                                    step="1">
                        Impostazione Scadenza
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="sc > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formSC"
                                    v-model="validSC">
                                <v-row>

                                    <v-col cols="8" offset="2">
                                        <v-row class="mt-2">
                                            <v-select v-model="idScadenza"
                                                      :items="listascadenze"
                                                      item-value="id"
                                                      item-text="descrizione"
                                                      label="Scadenza"
                                                      outlined
                                                      :rules="[v=> !!v || 'selezionare scadenza']"
                                                      dense></v-select>
                                        </v-row>
                                        <v-row>
                                            <v-select v-model="idOperatore"
                                                      :items="listaoperatori"
                                                      item-value="id"
                                                      item-text="denominazioneNormalizzata"
                                                      label="Operatore"
                                                      outlined
                                                      :rules="[v=> !!v || 'selezionare operatore']"
                                                      dense></v-select>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <vuetify-money v-model="importoScadenza"
                                                               label="Importo scadenza"
                                                               outlined
                                                               dense
                                                               :background-color="theme ? 'dark-grey':'white'"
                                                               :rules="[v=> !!v || 'inserire importo']"
                                                               :options="moneyOptions">
                                                </vuetify-money>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="dataScadenza"
                                                              label="Data scadenza"
                                                              outlined
                                                              :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                              hint="gg/mm/aaaa"
                                                              persistent-hint
                                                              clearable
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validSC" :loading="loadingSC" color="primary" depressed
                                       @click="stepSC1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogSC = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="6">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-calendar-sync-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{descrizioneScadenza}}</v-list-item-title>
                                            <v-list-item-subtitle>Tipo scadenza</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-calendar-account</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{descrizioneOperatore}}</v-list-item-title>
                                            <v-list-item-subtitle>Operatore assegnato</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-cash-clock</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{dataScadenza}}</v-list-item-title>
                                            <v-list-item-subtitle>data di pagamento</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-account-multiple</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{selezione.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti selezionati</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-cash-multiple</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{importoScadenzaEuro}}</v-list-item-title>
                                            <v-list-item-subtitle>importo scadenza</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="sc = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendSC">
                                    Genera scadenza
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogSC = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingSC" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingSC && esitoSCOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingSC && !esitoSCOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogSC = false"
                                       color="warning"
                                       :disabled="loadingSC">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>


        <v-dialog v-model="dialogCT" persistent
                  max-width="800">
            <v-stepper v-model="ct">
                <v-stepper-header>
                    <v-stepper-step :complete="ct > 1"
                                    step="1">
                        Impostazione copia tariffe
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="ct > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formCT"
                                    v-model="validCT">
                                <v-row>

                                    <v-col cols="8" offset="2">
                                        <!--<v-row class="mt-2">
                                        <v-select v-model="idScadenza"
                                                  :items="listascadenze"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Scadenza"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare scadenza']"
                                                  dense></v-select>
                                    </v-row>-->
                                        <v-row class="mt-2">
                                            <v-autocomplete v-model="idClienteCopia"
                                                            :items="listaCliente"
                                                            item-value="cliente.id"
                                                            item-text="cliente.denominazioneNormalizzata"
                                                            label="Cliente seme"
                                                            :rules="[v=> !!v || 'indicare il cliente']"
                                                            outlined
                                                            dense></v-autocomplete>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-radio-group :rules="[v=> !!v || 'indicare il tipo di copia']" v-model="tipoCopia"
                                                               column>
                                                    <v-radio value=1
                                                             color="info"
                                                             label="Copiare le tariffe su tutti i clienti, sovrascrivendo i dati eventualmente presenti">
                                                    </v-radio>
                                                    <v-radio value=0
                                                             color="info"
                                                             label="Copiare le tariffe sui soli clienti senza tariffe impostate">
                                                    </v-radio>

                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validCT" :loading="loadingCT" color="primary" depressed
                                       @click="stepCT1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCT = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="12">
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-calendar-account</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{descrizioneClienteSeme}}</v-list-item-title>
                                            <v-list-item-subtitle>Cliente seme selezionato</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-cash-clock</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{descrizioneTipoCopia}}</v-list-item-title>
                                            <v-list-item-subtitle>Tipologia di copia</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-icon>
                                            <v-icon>mdi-account-multiple</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">{{selezione.length}}</v-list-item-title>
                                            <v-list-item-subtitle>Clienti selezionati</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="ct = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendCT">
                                    Genera scadenza
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCT = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingCT" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCT && esitoCTOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingCT && !esitoCTOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogCT = false"
                                       color="warning"
                                       :disabled="loadingCT">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogSD" persistent
                  max-width="800">
            <v-stepper v-model="sd">
                <v-stepper-header>
                    <v-stepper-step :complete="sd > 1"
                                    step="1">
                        Scelta modello
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="sd > 2"
                                    step="2">
                        Riepilogo
                    </v-stepper-step>


                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Esito
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formSD"
                                    v-model="validSD">
                                <v-row>

                                    <v-col cols="6" offset="3">
                                        <v-select v-model="idModello"
                                                  :items="listamodelli"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Modello documento"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare il documento']"
                                                  dense></v-select>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validSD" :loading="loadingSD" color="primary" depressed
                                       @click="stepSD1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogSD = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="6" offset="1">
                                    <p>Modello del documento da generare</p>
                                    <p class="text-h6">{{this.descrizioneModello}}</p>
                                </v-col>
                                <v-col cols="4">
                                    <p>Clienti selezionati</p>
                                    <p class="text-h6">{{this.conteggioClienti}}</p>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="sd = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="success" depressed
                                       @click="sendSD">
                                    Genera documento
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogSD = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-container class="mb-12" align="center">
                            <v-col cols="6" offset="3">
                                <v-card v-if="loadingSD" color="primary"
                                        dark>
                                    <v-card-text>
                                        Esecuzione Operazione...
                                        <v-progress-linear indeterminate
                                                           color="white"
                                                           class="mb-0">
                                        </v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingSD && esitoSDOK" color="success"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        Operazione eseguita correttamente
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="!loadingSD && !esitoSDOK" color="error"
                                        dark>
                                    <v-card-text>
                                        <v-icon dark>
                                            mdi-alert-circle
                                        </v-icon>
                                        Errore durante l'esecuzione dell'operazione
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-container>

                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogSD = false"
                                       color="warning"
                                       :disabled="loadingSD">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-dialog v-model="dialogBP" persistent
                  max-width="1000">
            <v-stepper v-model="bp">
                <v-stepper-header>
                    <v-stepper-step :complete="bp > 1"
                                    step="1">
                        Scelta mensilità
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="bp > 2"
                                    step="2">
                        Indicazione numerosità e addebito
                    </v-stepper-step>

                </v-stepper-header>

                <v-stepper-items>

                    <v-stepper-content step="1">
                        <v-container class="mb-12">
                            <v-form ref="formBP"
                                    v-model="validBP">
                                <v-row>

                                    <v-col cols="6" offset="3">
                                        <v-select v-model="idPeriodo"
                                                  :items="periodi"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Mensilità"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare mensilità']"
                                                  dense></v-select>
                                        <v-row>
                                            <v-checkbox class="ml-2" v-model="notifica"
                                                        label="notifica addebito in StudioPro App"
                                                        color="red darken-3">
                                            </v-checkbox>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!validBP" :loading="loadingBP" color="primary" depressed
                                       @click="stepBP1">
                                    Avanti
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogBP = false" color="warning">
                                    Annulla
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container class="mb-12">
                            <v-row>
                                <v-col cols="3">
                                    <v-text-field class="mt-3 mx-0" v-model="searchBP"
                                                  append-icon="mdi-magnify"
                                                  label="Ricerca cliente"
                                                  single-line
                                                  persistent-hint
                                                  outlined
                                                  clearable
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-subtitle-2">
                                                {{bpLock}}
                                                <v-icon class="ml-2" color="grey">mdi-checkbox-blank-circle</v-icon>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>addebito già presente</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-subtitle-2">
                                                {{bpNuovi}}
                                                <v-icon class="ml-2" color="green">mdi-checkbox-blank-circle</v-icon>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>addebito inserito</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-subtitle-2">
                                                {{bpLiberi}}
                                                <v-icon class="ml-2" color="blue">mdi-checkbox-blank-circle</v-icon>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>addebito da inserire</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n10">
                                <v-col cols="3">
                                    <v-text-field v-model="dataAddebito"
                                                  label="Data addebito"
                                                  outlined
                                                  :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                  hint="gg/mm/aaaa"
                                                  persistent-hint
                                                  clearable
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="8">
                                    <span style="font-size:x-small; color:red">
                                        I clienti cancellati, bloccati o con addebiti sospesi sono automaticamente esclusi da questo conteggio
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table :headers="headersBP"
                                                  :items="clientiTariffa"
                                                  item-key="cliente.id || tariffa.id"
                                                  ref="tabellaClientiBP"
                                                  :loading="loadingBP"
                                                  :items-per-page="5"
                                                  class="elevation-1"
                                                  :item-class="coloraRiga"
                                                  :footer-props="{
                                                    'items-per-page-text':'Pagina da',
                                                    'items-per-page-options':[2,5,10]
                                                  }"
                                                  :search="searchBP"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun cliente corrispodende ai criteri di ricerca"
                                                  no-results-text="Nessun cliente corrispodende ai criteri di ricerca">
                                        <template v-slot:header.tariffa.tipoServizio.descrizione="{ item }">
                                            {{descrizionePeriodo}}
                                        </template>
                                        <template v-slot:item.colore="{item}">
                                            <v-icon :color="item.colore">mdi-checkbox-blank-circle</v-icon>
                                        </template>
                                        <template v-slot:item.tariffa.importo="{item}">
                                            <span v-text="importoEuro(item.tariffa.importo)" />
                                        </template>
                                        <template v-slot:item.numero="{item}">
                                            <div v-if="item.lock">
                                                <span v-if="item.numero>0" v-text="item.numero" />
                                                <span v-else>-</span>
                                            </div>
                                            <v-edit-dialog v-if="!item.lock" persistent
                                                           @save="saveNumero(item)"
                                                           @open="numeroEdit = isNaN(item.numero)||item.numero==0 ? '': item.numero ">
                                                <span v-if="item.numero>0" v-text="item.numero" />
                                                <span v-else>-</span>
                                                <template v-slot:input>
                                                    <v-text-field class="mt-2 mb-0" v-model="numeroEdit"
                                                                  :rules="[v=> !v || !isNaN(parseInt(v)) || 'inserire un numero',v=> !item.lockNumero || item.lockNumero && (v==1 || v==0) || 'è possibile impostare solo 0 o 1']"
                                                                  label="Numero"
                                                                  outlined
                                                                  single-line
                                                                  dense></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>
                                        <template v-slot:item.totale="{item}">
                                            <span v-if="item.totale>0" v-text="importoEuro(item.totale)" />
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="bp = 1">
                                    Indietro
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn :disabled="!bpAbilitaSave || loadingBP"
                                       color="success" depressed
                                       @click="sendBP">
                                    Addebita lavoro
                                    <v-icon right>
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2">
                                <v-btn @click="dialogBP = false" color="warning">
                                    Chiudi
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <msg-list-dialog />

        <addebito-spesa-dialog :type="'clienti'" />

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

    </v-container>

</template>

<script>import { callService, callPost, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import AddebitoSpesaDialog from '@/views/components/AddebitoSpesaDialog.vue'
    import MsgListDialog from '@/views/components/MsgListDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components: {
            MsgListDialog,
            AddebitoSpesaDialog,
        },
        data: () => ({
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogCliente: false,
            selezione: [],
            filtro: [],
            statoAll: false,
            loading: true,
            showError: false,
            viewMenuCliente: false,
            isAccessoLimitatoO: false,
            isAccessoLimitatoP: false,
            x: 0,
            y: 0,
            search: '',
            searchState: [],
            searchText: '',
            searchBP: '',
            cliente: [],
            clientiEstesi: [],
            clientiEstesiPromise: '',
            headers: [
                { text: 'Azioni', value: 'azioni', sortable: false,width: '10%', align:'center'  },
                { text: 'Codice', value: 'cliente.codice', width: '10%' },
                { text: 'Nominativo', value: 'cliente.denominazioneNormalizzata', width:'55%'},
                { text: 'Saldo', value: 'saldo', align: 'right' },
                { text: 'Ultimo Pagamento', align: 'center', value: 'dataUltimoPagamento' },
                { text: 'Operatori', value: 'listaNomi', width: '15%' },
                { text: 'Etichette', value: 'cliente.tags', width: '15%' },
                { text: 'Contratti', value: 'bTariffaContratto', width: '15%' },
                { text: 'Elimina', value: 'elimina' },
            ],
            nPag: 8,
            theme: '',
            mb: 1,
            dialogMB: false,
            oggetto: '',
            testo: '',
            validMB: true,
            loadingMB: false,
            esitoMBOK: false,
            esitoMBKO: false,
            as: 1,
            dialogAS: false,
            validAS1: false,
            validAS2: false,
            loadingAS: false,
            esitoASOK: false,
            idTipoConsulenza: '',
            tipiConsulenza: [],
            idTipoServizio: '',
            tipiServizioEstesi: [],
            descrizioneAddebito: '',
            dataAddebito: '',
            importoAddebito: 0,
            periodi: [],
            conti: [],
            listamodelli: [],
            listaoperatori: [],
            listaCliente: [],
            listascadenze: [],
            listaDoc: [],
            idModello: '',
            idScadenza: '',
            idClienteCopia: '',
            idOperatore: '',
            importoScadenza: 0,
            periodiConnessi: [],
            idPeriodo: '',
            idConto: '',
            ca: 1,
            dialogCA: false,
            validCA: false,
            loadingCA: false,
            esitoCAOK: false,
            cs: 1,
            dialogCS: false,
            validCS: false,
            loadingCS: false,
            esitoCSOK: false,
            cso: 1,
            dialogCSO: false,
            validCSO: false,
            loadingCSO: false,
            esitoCSOOK: false,
            aib: 1,
            dialogAIB: false,
            validAIB: false,
            loadingAIB: false,
            esitoAIBOK: false,
            sd: 1,
            dialogSD: false,
            validSD: false,
            loadingSD: false,
            esitoSDOK: false,
            sc: 1,
            dialogSC: false,
            validSC: false,
            loadingSC: false,
            esitoSCOK: false,
            ct: 1,
            dialogCT: false,
            validCT: false,
            loadingCT: false,
            esitoCTOK: false,
            tipoCopia: 1,
            loadingExcel: false,
            loadingPdf: false,
            fileExcel:'',
            filePdf: '',
            dataScadenza: '',
            bp: 1,
            dialogBP: false,
            validBP: false,
            loadingBP: false,
            esitoBPOK: false,
            lockImporto: false,
            headersBP: [
                { text: 'Lock', value: 'lock', align: ' d-none' },
                { text: 'Stato', value: 'colore' },
                { text: 'Codice', value: 'cliente.codice' },
                { text: 'Nominativo', value: 'cliente.denominazioneNormalizzata' },
                { text: 'Tipo Tariffa', value: 'tariffa.tipoServizio.tipoPeriodicita.descrizione', filterable: false },
                { text: 'Descrizione', value: "tariffa.tipoServizio.descrizione", filterable: false },
                { text: 'Importo', value: 'tariffa.importo', filterable: false },
                { text: 'Numero', value: 'numero', filterable: false },
                { text: 'Totale', value: 'totale', filterable: false },
            ],
            numeroEdit: 0,
            clientiTariffa: [],
            conteggioClienti: [],
            listaClientiNoTariffa: [],
            listaClientiTariffaAddebitata: [],
            listaClientiNoAddebito: [],
            listaClientiOk: [],
            notifica: true,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            operatore: { id: '' },
            professionista: { id: '' },
            regimeIVA: { id: '' },
            regimeContabile: { id: '' },
            tipoCliente: { id: '' },
            fido: '',
            statuspec: '',
            statuscliente: [],
            /*statoCliente: '1'*/
        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            checkDataOggiOFutura(d) {
                if(!d) return true;
                var tok = d.split("/");
                var dIso = new Date([tok[2], tok[1], tok[0]].join("-"));
                let oggi = new Date();
                oggi.setHours(0, 0, 0);
                return dIso.getTime() >= oggi.getTime();
            },
            serverData(v) {
                return gServerData(v);
            },
            async elimina(item) {
                bus.$emit('msg-open', { message: "La cancellazione eliminerà tutti i riferimenti a questo elemento. Resteranno, se ve ne sono, i movimenti relativi agli anni trascorsi. Si conferma di voler eliminare il cliente?", item });
                bus.$on('msg-cancel', this.eliminaCancel);
                bus.$on('msg-ok', this.eliminaOk);
            },
            async eliminaCancel(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);
            },
            async eliminaOk(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    id: item.cliente.id
                };

                this.loading = true;

                const result = await callPost('api/cliente/eliminaCliente', param);

                if (result) {
                    this.snackSuccess = true;
                    bus.$emit('cliente-deleted', { lista: [item.cliente.id], sessionID: "-1" });
                }
                else {
                    this.snackError = true;
                }
                this.loading = false;
            },
            async stepAS1() {
                this.loadingAS = true;
                let param;
                if (this.selezione.length > 1) {
                    param = [this.idTipoConsulenza];
                }
                else {
                    param = [this.idTipoConsulenza, this.selezione[0].cliente.id];
                }

                this.tipiServizioEstesi = await callService('api/tiposervizio/spot', param);
                this.as = 2;
                this.loadingAS = false;
            },
            async stepCA1() {
                this.loadingCA = true;
                let param = [this.idPeriodo];
                this.periodiConnessi = await callService('api/periodo/periodiConnessi', param);

                param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let output = await callPost('api/addebito/canone', param);
                this.conteggioClienti = output.conteggioClienti;
                this.listaClientiOk = output.listaClientiOk;
                this.listaClientiNoTariffa = output.listaClientiNoTariffa;
                this.listaClientiTariffaAddebitata = output.listaClientiTariffaAddebitata;
                this.listaClientiNoAddebito = output.listaClientiNoAddebito;
                this.ca = 2;
                this.loadingCA = false;
            },
            async stepCS1() {
                this.loadingCS = true;
                let param = [this.idPeriodo];
                this.periodiConnessi = await callService('api/periodo/periodiConnessi', param);

                param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let output = await callPost('api/addebito/canoneservicepro', param);
                this.conteggioClienti = output.conteggioClienti;
                this.listaClientiOk = output.listaClientiOk;
                this.listaClientiNoTariffa = output.listaClientiNoTariffa;
                this.listaClientiTariffaAddebitata = output.listaClientiTariffaAddebitata;
                this.listaClientiNoAddebito = output.listaClientiNoAddebito;
                this.cs = 2;
                this.loadingCS = false;
            },
            async stepCSO1() {
                this.loadingCSO = true;
                let param = [this.idPeriodo];
                this.periodiConnessi = await callService('api/periodo/periodiConnessi', param);

                param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let output = await callPost('api/addebito/eliminacanoneservicepro', param);
                this.conteggioClienti = output.conteggioClienti;
                this.listaClientiOk = output.listaClientiOk;
                this.listaClientiNoTariffa = output.listaClientiNoTariffa;
                this.listaClientiTariffaAddebitata = output.listaClientiTariffaAddebitata;
                this.listaClientiNoAddebito = output.listaClientiNoAddebito;
                this.cso = 2;
                this.loadingCSO = false;
            },
            async stepAIB1() {
                this.loadingAIB = true;
                this.aib = 2;
                this.loadingAIB = false;
            },
            async listaExcel() {
                this.loadingExcel = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let outputPdf = await callPost('api/excel/listaclienti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                }
                else this.snackError = true;
                this.loadingExcel = false;
            },
            scaricaExcel() {
                //alert(this.filePdf);
                window.open(this.fileExcel);
                this.fileExcel = '';
            },
            async programmaPdf() {
                this.loadingPdf = true;
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let outputPdf = await callPost('api/pdf/stampaschedamultilista', paramJson);
                if (outputPdf) {
                    this.filePdf = outputPdf.fileOutput;
                }
                else this.snackError = true;
                this.loadingPdf = false;
            },
            visualizzaPdf() {
                //alert(this.filePdf);
                window.open(this.filePdf);
                this.filePdf = '';
            },
            async stepSD1() {
                this.loadingSD = true;
                this.conteggioClienti = this.selezione.length;
                this.sd = 2;
                this.loadingSD = false;
            },
            async stepSC1() {
                this.loadingSC = true;
                this.conteggioClienti = this.selezione.length;
                this.sc = 2;
                this.loadingSC = false;
            },
            async stepCT1() {
                this.loadingCT = true;
                this.conteggioClienti = this.selezione.length;
                this.ct = 2;
                this.loadingCT = false;
            },
            async stepBP1() {
                this.loadingBP = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                this.clientiTariffa = await callPost('api/addebito/bustepaga', param);
                this.bp = 2;
                this.loadingBP = false;
            },
            async sendAS() {
                this.loadingAS = true;
                this.as = 4;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    data: this.serverData(this.dataAddebito),
                    idTipoServizio: this.idTipoServizio,
                    importo: this.importoAddebito,
                    descrizione: this.descrizioneAddebito,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    notifica: this.notifica
                };

                this.esitoASOK = await callPost('api/addebito/spesa', param);
                if (this.esitoASOK) {
                    bus.$emit('cliente-changed', { lista: param.listaClienti.map(c => c.id), sessionID: "-1" });
                }
                this.loadingAS = false;
            },
            async sendSC() {
                this.loadingSC = true;
                this.sc = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    scadenza: this.descrizioneScadenza,
                    idOperatore: this.idOperatore,
                    importo: this.importoScadenza,
                    data: this.serverData(this.dataScadenza),
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    notifica: this.notifica,
                };

                //alert(JSON.stringify(param));

                this.esitoSCOK = await callPost('api/scadenze/generascadenza', param);
                this.loadingSC = false;
            },
            async sendCT() {
                this.loadingCT = true;
                this.ct = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idCliente: this.idClienteCopia,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    tipoCopia: this.tipoCopia,
                };

                //alert(JSON.stringify(param));

                this.esitoCTOK = await callPost('api/tariffaCliente/copiaTariffe', param);
                this.loadingCT = false;
            },
            async sendMB() {
                this.loadingMB = true;
                this.mb = 3;

                //imposto lettura

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    oggetto: this.oggetto,
                    testo: this.testo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };

                const result = await callPost('api/broadcast/inviaMessaggio', param);

                this.esitoMBKO = !result;
                this.esitoMBOK = result;
                this.loadingMB = false;
            },
            async sendCA() {
                this.loadingCA = true;
                this.ca = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    addebita: true,
                    data: this.serverData(this.dataAddebito),
                    notifica: this.notifica
                };
                this.esitoCAOK = await callPost('api/addebito/canone', param);
                if (this.esitoCAOK) {
                    bus.$emit('cliente-changed', { lista: param.listaClienti.map(c => c.id), sessionID: "-1" });
                }
                this.loadingCA = false;
            },
            async sendCS() {
                this.loadingCS = true;
                this.cs = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    addebita: true,
                    data: this.serverData(this.dataAddebito),
                    notifica: this.notifica
                };
                this.esitoCSOK = await callPost('api/addebito/canoneservicepro', param);
                if (this.esitoCSOK) {
                    bus.$emit('cliente-changed', { lista: param.listaClienti.map(c => c.id), sessionID: "-1" });
                }
                this.loadingCS = false;
            },
            async sendCSO() {
                this.loadingCSO = true;
                this.cso = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    addebita: true,
                    data: this.serverData(this.dataAddebito),
                    notifica: this.notifica
                };
                this.esitoCSOOK = await callPost('api/addebito/eliminacanoneservicepro', param);
                if (this.esitoCSOOK) {
                    bus.$emit('cliente-changed', { lista: param.listaClienti.map(c => c.id), sessionID: "-1" });
                }
                this.loadingCSO = false;
            },
            async sendAIB() {
                this.loadingAIB = true;
                this.aib = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idConto: this.idConto,
                    listaClienti: this.selezione.map(ce => ce.cliente)
                };
                this.esitoAIBOK = await callPost('api/conto/assegnaconto', param);
                if (this.esitoAIBOK) {
                    bus.$emit('cliente-changed', { lista: param.listaClienti.map(c => c.id), sessionID: "-1" });
                }
                this.loadingAIB = false;
            },
            async sendSD() {
                this.loadingSD = true;
                this.sd = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idModello: this.idModello,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    notifica: this.notifica
                };
                this.listaDoc = await callPost('api/documenti/generadocumento', param);
                if (!this.listaDoc) {
                    this.esitoSDOK = false;
                }
                else {
                    this.esitoSDOK = true;
                    window.open("/" + this.listaDoc[0], '_self');
                }
                this.loadingSD = false;
            },
            async sendBP() {
                this.loadingBP = true;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idPeriodo: this.idPeriodo,
                    data: this.serverData(this.dataAddebito),
                    listaClientiTariffa: this.clientiTariffa.filter(c => !c.lock && c.numero > 0),
                    notifica: this.notifica
                };
                this.esitoBPOK = await callPost('api/addebito/addebitobustepaga', param);

                if (this.esitoBPOK) {
                    this.snackSuccess=true;
                    bus.$emit('cliente-changed', { lista: param.listaClientiTariffa.map(c => c.cliente.id), sessionID: "-1" });
                }
                else {
                    this.snackError=true;
                }

                await this.stepBP1(); //vado al passo precedente aggiornando la tabella

                this.loadingBP = false;

            },

            viewMB() {
                this.mb = 1;
                this.loadingMB = false;
                this.dialogMB = true;
                this.$nextTick(() => {
                    this.$refs.formMB.resetValidation();
                });
            },
            async viewBP() {
                let param = ['L'];
                this.periodi = await callService('api/periodo', param);

                this.bp = 1;
                this.loadingBP = false;
                this.dialogBP = true;
                this.$nextTick(() => {
                    this.$refs.formBP.resetValidation();
                    this.$refs.formBP.reset();
                    this.$nextTick(() => {
                        this.notifica = true;
                        this.dataAddebito = this.localData(new Date());
                    });
                });
            },
            async viewCA() {
                let param = ['M'];
                this.periodi = await callService('api/periodo', param);

                this.ca = 1;
                this.loadingCA = false;
                this.dialogCA = true;
                this.$nextTick(() => {
                    this.$refs.formCA.resetValidation();
                    this.$refs.formCA.reset();
                    this.$nextTick(() => {
                        this.dataAddebito = this.localData(new Date());
                        this.notifica = true;
                    });
                });
            },
            async viewCS() {
                let param = ['M'];
                this.periodi = await callService('api/periodo/periodoServicePro', param);

                this.cs = 1;
                this.loadingCS = false;
                this.dialogCS = true;
                this.$nextTick(() => {
                    this.$refs.formCS.resetValidation();
                    this.$refs.formCS.reset();
                    this.$nextTick(() => {
                        this.dataAddebito = this.localData(new Date());
                        this.notifica = true;
                    });
                });
            },
            async viewAIB() {
                let param = [];
                this.conti = await callService('api/conto/listaconti', param);

                this.aib = 1;
                this.loadingAIB = false;
                this.dialogAIB = true;
                this.$nextTick(() => {
                    this.$refs.formAIB.resetValidation();
                    this.$refs.formAIB.reset();
                    this.$nextTick(() => {
                    //    this.dataAddebito = this.localData(new Date());
                    //    this.notifica = true;
                    });
                });
            },
            async viewCSO() {
                let param = ['M'];
                this.periodi = await callService('api/periodo/periodoServicePro', param);

                this.cso = 1;
                this.loadingCSO = false;
                this.dialogCSO = true;
                this.$nextTick(() => {
                    this.$refs.formCSO.resetValidation();
                    this.$refs.formCSO.reset();
                    this.$nextTick(() => {
                        this.dataAddebito = this.localData(new Date());
                        this.notifica = true;
                    });
                });
            },
            async viewSC() {
                let param = [];
                this.listascadenze = this.$store.state.listaTipoScadenza;
                this.listaoperatori = JSON.parse(JSON.stringify(this.$store.state.listaOperatore));
                let opDef = {
                    id: '0',
                    denominazioneNormalizzata: '<operatore di default>'
                };
                this.listaoperatori.splice(0, 0, opDef);

                this.sc = 1;
                this.loadingSC = false;
                this.dialogSC = true;
                this.$nextTick(() => {
                    this.$refs.formSC.resetValidation();
                    this.$refs.formSC.reset();
                    this.$nextTick(() => {
                        this.notifica = true;
                        this.idOperatore = "0";
                    });
                });
            },
            async viewCT() {
                this.ct = 1;
                this.loadingCT = false;
                this.dialogCT = true;
                this.$nextTick(() => {
                    this.$refs.formCT.resetValidation();
                    this.$refs.formCT.reset();
                    this.$nextTick(() => {
                        //this.tipoCopia = 1;
                        //this.idClienteCopia = "0";
                    });
                });
            },
            async viewSD() {
                let param = [];
                this.listamodelli = await callService('api/documenti/listamodelli', param);

                this.sd = 1;
                this.loadingSD = false;
                this.dialogSD = true;
                this.$nextTick(() => {
                    this.$refs.formSD.resetValidation();
                    this.$refs.formSD.reset();
                    this.$nextTick(() => {
                        this.notifica = true;
                    });
                });
            },
            coloraRiga(item) {
                if (item.colore == "grey") return "style-lock";
                return "";
            },
            coloraStatus(item) {
                //if (item.cliente.isSospeso) return "style-suspended";
                //if (item.cliente.isAddebitiSospesi) return "style-fee-suspended";
                //if (item.cliente.isCancellato) return "style-deleted";
                return "";
            },
            viewAS() {
                bus.$emit('dialog-show-as', this.selezione,'clienti');
            },
            filterItems(v) {
                this.$nextTick(() => {
                    this.filtro = this.$refs.tabellaClienti.$children[0].filteredItems;
                });
            },
            saveNumero(item) {
                if (item.lockNumero && this.numeroEdit && this.numeroEdit != 1 && this.numeroEdit != 0) return;
                item.numero = Number(this.numeroEdit);
                item.totale = item.numero * item.tariffa.importo;
                if (item.numero > 0) item.colore = "green";
                if (item.numero == 0) item.colore = "blue";
            },
            anagrafica(cliente) {
                this.cliente = JSON.parse(JSON.stringify(cliente));
                bus.$emit('dialog-show-cliente', this.cliente);
                //this.$router.replace({ name: "cliente", query: { id: cliente.id } });
            },
            scheda(cliente) {
                this.cliente = JSON.parse(JSON.stringify(cliente));
                bus.$emit('dialog-show-scheda', this.cliente);
            },
            nuovo() {
                this.cliente.id = "0";
                bus.$emit('dialog-show-cliente', this.cliente);
                //this.$router.replace({ name: "cliente", query: { id: "0" } });
            },
            toggleAll() {
                this.statoAll = !this.statoAll;
                this.$store.dispatch('setStatoAll', this.statoAll);

                if (this.statoAll) {
                    this.selezione = this.filtro;
                }
                else {
                    this.selezione = [];
                };
                this.$store.dispatch('setSelezione', this.selezione);
            },
            toggleSelect() {
                this.$nextTick(() => {
                    this.$store.dispatch('setSelezione', this.selezione);
                });
            },
            setImporto(tariffa) {
                this.lockImporto = tariffa != null;

                if (tariffa == null) this.importoAddebito = null;
                else this.importoAddebito = tariffa.importo;
            },
            setTagRicerca(tag) {
                this.searchText = tag;
                this.changeSearch();
            },
            pagination(pag) {
                let nPag = pag.itemsPerPage;
                this.$cookie.set('clienti-pagination', nPag.toString(), { expires: '1Y' });
            },
            clickRowCliente(item) {
                this.selezione = [item];
            },
            customFilter(value, search, item) {
                let ok = true;
                if (ok && this.searchText) { //codice/dnz/fiscale in OR
                    let src = this.searchText.toLowerCase();
                    src = src.replace(/\s+/g, ' '); //elimino i doppi spazi interni
                    src = src.trim();
                    var tokens = src.split(" ");
                    var i=0;
                    //console.log("*"+src+"*");
                    while (ok && i<tokens.length) {
                        let srcToken = tokens[i];
                        let ok1 = item.cliente.denominazioneNormalizzata && item.cliente.denominazioneNormalizzata.toLowerCase().indexOf(srcToken) >= 0;
                        let ok2 = !ok1 && item.cliente.denominazioneNormalizzataRL && item.cliente.denominazioneNormalizzataRL.toLowerCase().indexOf(srcToken) >= 0;
                        let ok3 = !ok2 && item.cliente.codice && item.cliente.codice.toLowerCase().indexOf(srcToken) >= 0;
                        let ok4 = !ok3 && item.cliente.codiceFiscale && item.cliente.codiceFiscale.toLowerCase().indexOf(srcToken) >= 0;
                        let ok5 = !ok4 && item.cliente.codiceFiscaleLR && item.cliente.codiceFiscaleLR.toLowerCase().indexOf(srcToken) >= 0;
                        let ok6 = !ok5 && item.cliente.tags && item.cliente.tags.toLowerCase().indexOf(srcToken) >= 0;
                        let ok7 = !ok6 && item.cliente.partitaIVA && item.cliente.partitaIVA.toLowerCase().indexOf(srcToken) >= 0;

                        ok = ok1 || ok2 || ok3 || ok4 || ok5 || ok6 || ok7;
                        i++;
                    }                    
                }
                if (ok && this.operatore.id) {
                    ok = item.operatori.findIndex(o => o.id == this.operatore.id) >= 0;
                }
                if (ok && this.professionista.id) {
                    ok = item.cliente.professionista.id == this.professionista.id;
                }
                if (ok && this.regimeIVA.id) {
                    ok = (item.cliente.tipoRegimeIVA && item.cliente.tipoRegimeIVA.id == this.regimeIVA.id);
                }
                if (ok && this.regimeContabile.id) {
                    ok = (item.cliente.tipoRegimeContabile && item.cliente.tipoRegimeContabile.id == this.regimeContabile.id);
                }
                if (ok && this.tipoCliente.id) {
                    ok = (item.cliente.tipoCliente && item.cliente.tipoCliente.id == this.tipoCliente.id);
                }
                if (ok && this.fido) {
                    ok = this.fido == item.fido;
                }
                if (ok && this.statuspec) {
                    ok = this.statuspec == item.statuspec;
                }
                if (ok && this.statuscliente.length>0) {
                    let result = this.statuscliente.find(e=> e == item.statuscliente);
                    ok = result && result.length>0;
                }
                //if (ok) {
                //    if (this.statoCliente == '1') //TUTTI
                //        ok = ok;
                //    else if (this.statoCliente == '2') //ATTIVI
                //        ok = !item.cliente.isSospeso && !item.cliente.isSospeso
                //    else if (this.statoCliente == '3') //SOSPESI
                //        ok = item.cliente.isSospeso
                //    else if (this.statoCliente == '4') //ELIMINATI
                //        ok = item.cliente.isCancellato
                //}

                //ultimo check: escludo i clienti eliminati
                //ok = ok && !this.isEliminato;

                return ok;
            },
            changeSearch() {
                this.searchState =
                    [this.searchText,
                    this.operatore.id,       
                    this.professionista.id, 
                    this.regimeIVA.id,     
                    this.regimeContabile.id, 
                    this.tipoCliente.id, 
                    this.fido,  
                    this.statuspec,
                    this.statuscliente
                    //,this.statoCliente
                    ];
                    this.search=this.searchState.toString();
                    this.writeState(); //memorizzo lo stato della ricerca
            },
            visualizzaClienti(lista) {
                if (!lista) return;
                bus.$emit('msg-list-open', { list: lista.map(c => ({ ...c, desc: c.codice + " - " + c.denominazioneNormalizzata })), title: "Elenco Clienti" });
            },
            readState() {
                this.selezione = this.$store.state.selezione;
                this.statoAll = this.$store.state.statoAll;
                this.searchState= this.$store.state.searchState;
                this.searchText = this.searchState[0];
                this.operatore.id= this.searchState[1];       
                this.professionista.id= this.searchState[2]; 
                this.regimeIVA.id= this.searchState[3];     
                this.regimeContabile.id= this.searchState[4];
                this.tipoCliente.id = this.searchState[5];
                this.fido= this.searchState[6];        
                this.statoCliente= this.searchState[7];
                this.search=this.searchState.toString();
            },
            writeState() {

                //console.log(this.search);

                this.$store.dispatch('setStatoAll', false);
                this.$store.dispatch('setSelezione', this.selezione);
                this.$store.dispatch('setSearchState', this.searchState);

            }
        },
        computed: {
            isLavoro() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isLavoro;
            },
            isContabilita(){
                return this.$store.state.lplApp && this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            hintSelezione() {
                if (this.selezione.length == 1) return "1 cliente selezionato su " + this.filtro.length;
                if (this.selezione.length > 1) return this.selezione.length + " clienti selezionati su " + this.filtro.length;
                return "";
            },
            labelMB() {
                return "Messaggio Broadcasting (" + this.selezione.length + ")";
            },
            labelCA() {
                return "Canone Periodico (" + this.selezione.length + ")";
            },
            labelCS() {
                return "Contratti e Servizi (" + this.selezione.length + ")";
            },
            labelCSO() {
                return "Elimina addebiti Contratti e Servizi (" + this.selezione.length + ")";
            },
            labelAS() {
                return "Addebito Spesa (" + this.selezione.length + ")";
            },
            labelSD() {
                return "Stampa Documento (" + this.selezione.length + ")";
            },
            labelAIB() {
                return "Assegna IBAN (" + this.selezione.length + ")";
            },
            labelSC() {
                return "Scadenza (" + this.selezione.length + ")";
            },
            labelCT() {
                return "Copia Tariffe (" + this.selezione.length + ")";
            },
            importoAddebitoEuro() {
                return euro(this.importoAddebito);
            },
            importoScadenzaEuro() {
                return euro(this.importoScadenza);
            },
            descrizioneTipoServizio() {
                let ts = this.tipiServizioEstesi.find(x => x.tipoServizio.id === this.idTipoServizio);
                if (ts) return ts.tipoServizio.descrizione;
                else return "";
            },
            descrizioneTipoConsulenza() {
                let tc = this.tipiConsulenza.find(x => x.id === this.idTipoConsulenza);
                if (tc) return tc.descrizione;
                else return "";
            },
            descrizionePeriodo() {
                let p = this.periodi.find(x => x.id === this.idPeriodo);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneConto() {
                let p = this.conti.find(x => x.id === this.idConto);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneModello() {
                let p = this.listamodelli.find(x => x.id === this.idModello);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneScadenza() {
                let p = this.listascadenze.find(x => x.id === this.idScadenza);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneOperatore() {
                let p = this.listaoperatori.find(x => x.id === this.idOperatore);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            descrizioneClienteSeme() {
                //alert(this.idClienteCopia);
                let p = this.listaCliente.find(x => x.cliente.id === this.idClienteCopia);
                if (p) return p.cliente.denominazioneNormalizzata;
                else return "";
            },
            descrizioneTipoCopia() {
                if (this.tipoCopia == 1) return "Copiare le tariffe su tutti i clienti, sovrascrivendo i dati eventualmente presenti";
                else return "Copiare le tariffe sui soli clienti senza dati tariffari impostati";
            },
            bpNuovi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLock() {
                let n = this.clientiTariffa.filter(c => c.lock).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLiberi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero == 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpAbilitaSave() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                return n > 0 && this.dataAddebito;
            }
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;

            this.tipiConsulenza = this.$store.state.listaTipoConsulenza;
            this.clientiEstesi = this.$store.state.clientiEstesi;
            //console.log(JSON.stringify(this.clientiEstesi));
            this.listaOperatore = this.$store.state.listaOperatore;
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;
            this.listaTipoRegimeIVA = this.$store.state.listaTipoRegimeIVA;
            this.listaTipoRegimeContabile = this.$store.state.listaTipoRegimeContabile;
            this.listaTipoCliente = this.$store.state.listaTipoCliente;
            this.listaCliente = this.$store.state.clientiEstesi;

            this.nPag = Number(this.$cookie.get('clienti-pagination')); //leggo il coockie della paginazione
            this.statuscliente = ['verde', 'giallo', 'arancio'];

            this.readState();



            if (this.$store.state.utente.operatore.isAccessoLimitato && !this.$store.state.utente.isGestoreStudio && this.$store.state.utente.operatore.isProfessionista) {
                this.professionista.id = this.$store.state.utente.operatore.professionista.id;
                this.isAccessoLimitatoP = true;
            }
            else if (this.$store.state.utente.operatore.isAccessoLimitato && !this.$store.state.utente.isGestoreStudio) {
                this.operatore.id = this.$store.state.utente.operatore.id;
                this.isAccessoLimitatoO = true;
            }
            else if (this.$store.state.utente.operatore.isProfessionista && !this.$store.state.conf.isService) {
                this.professionista.id = this.$store.state.utente.operatore.professionista.id;
            }


            this.loading = false;
        },
        beforeDestroy() {

        }
    }</script>

<style>
    .style-lock {
        color: grey
    }

    .style-suspended {
        background-color: orange
    }

    .style-fee-suspended {
        background-color: yellow
    }

    .style-deleted {
        background-color: red
    }
</style>


    
